@import "~bulma/sass/utilities/initial-variables";
@import "variables";
@import "~bulma/bulma";
@import "~bulma-extensions/bulma-pageloader/src/sass";

$min-content-height: calc(100vh - #{$navbar-height});

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body, #root {
  min-height: $min-content-height;
  background-color: $background;
}

main {
  min-height: $min-content-height;

  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.title {
  color: $black;
}

.benefits {
  display: grid;
  gap: $size-4;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  svg {
    flex-shrink: 0;
    width: $size-4;
    height: $size-4;
  }

  & > div {
    display: flex;

    & > div:nth-child(1) {
      color: $black;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    & > div:nth-child(2) {
      padding-left: $size-7;
      font-size: $size-6;
      overflow-wrap: break-word;

      h4 {
        font-weight: 600;
        color: $black;
      }
    }
  }
}

.card.is-booking {
  border-radius: 1.5rem;

  .card-footer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
    opacity: 0;

    &.is-opened {
      opacity: 1;
      max-height: 80px;
      transition: all 0.5s ease-in-out;
      transition-property: opacity, max-height;
    }
  }
}

.fullscreen-qr-code {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;

  .qrcode {
    background-color: $white;
    padding: 10px;
    border-radius: 10px;
  }
}

.swiper {
  width: 100%;
  height: 100%;
  min-height: $min-content-height;
  --swiper-navigation-color: $black;
  --swiper-navigation-size: 40px;

  .swiper-button-next,
  .swiper-button-prev {
    --swiper-navigation-size: 24px;
    height: 44px;
    width: 44px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    box-shadow: $box-shadow;
    padding: 10px;

    &.swiper-button-disabled {
      display: none;
    }
  }

  .swiper-button-prev {
    right: 4px;
  }

  .swiper-button-next {
    right: 4px;
  }

  .swiper-pagination {
    color: $black;
    font-weight: 600;
    font-size: $size-7;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;

    .ticket {
      $ticket-padding: $size-6;

      background-color: $white;
      width: calc(95% - #{$ticket-padding});
      padding: $ticket-padding;
      border-radius: 1.5rem;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

      .ticket-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $size-7;

        h1 {
          font-size: $size-6;
          text-transform: uppercase;
          color: $black;
          font-weight: 600;
        }
      }

      .ticket-content {
        border-radius: $radius-large;

        .details {
          border-radius: $radius-large;
          background-color: $dark;
          color: #fff;
          padding: $size-7;
          margin-bottom: $size-6;

          h2 {
            font-weight: 600;
            font-size: $size-5;
            margin-bottom: $size-6;
          }

          .price-and-date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .date {
              flex-shrink: 1;
              font-size: $size-6;
              font-weight: 500;
              text-transform: uppercase;
            }

            .price {
              flex-shrink: 1;
              text-align: right;

              .amount {
                font-weight: 700;
                font-size: $size-4;
              }

              .vat {
                font-size: 0.65rem;
                text-transform: uppercase;
              }
            }
          }

          .customer-info {
            padding-top: $size-7;

            .customer-name {
              font-size: $size-5;
              font-weight: 600;
            }

            .ticket-category {
              font-weight: 500;
              font-size: $size-7;
            }
          }
        }

        .separator {
          $cutout-size: $size-3;

          position: relative;
          border-top: 2px dashed $grey-light;
          margin-bottom: $size-6;
        }

        .stub {
          text-align: center;

          .qrcode {
            display: inline-block;
            margin: 0 auto;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          .ticket_id {
            margin: 2px;
            font-weight: 600;
            color: $grey-light;
            font-size: $size-7;
          }
        }
      }
    }
  }
}
